

// Component Functions
import MainNavbarComponent from "../components/MainNavbar";


export default function NewsRoute()
{
  return (
    <div>
      <MainNavbarComponent />
      <div className="content-holder">
        <h2>"News Page!"</h2>
        <button className="donate-button">Donate</button>
      </div>
    </div>
  );
};