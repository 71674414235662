
// Libraries
import { Link } from "react-router-dom";

// CSS
import './MainNavbar.css';


// <ul>
//   <li><Link to="/">Home</Link></li>
//   <li><Link to="/news">News</Link></li>
//   <li><Link to="/donate">Donate</Link></li>
// </ul>



export default function MainNavbarComponent()
{
  return (
    <div className="main-navbar-div">
      <h1><Link to="/">Amir Mohammad Yazdani</Link></h1>
    </div>
  );
};