// Libraries
import { Route, Routes } from "react-router-dom";


// Route Functions
import HomeRoute from "./routes/Home";
import NewsRoute from "./routes/News";
import DonateRoute from "./routes/Donate";

import Error404Route from "./routes/Error404";

export default function App()
{
  return (
    <Routes>
      <Route path="/" element={<HomeRoute />} />
      <Route path="/blog" element={<NewsRoute />} />

      
      <Route path="/*" element={<Error404Route />} />
    </Routes>
  );
};
