

// Component Functions

// Libraries
import { Link } from "react-router-dom";

export default function Error404Route()
{
  return (
    <div>
      <div className="content-holder">
        <h2>404 not found</h2>
        <button className="donate-button"><Link to="/">Back to home page</Link></button>
      </div>
    </div>
  );
};