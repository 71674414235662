
// Component Functions
import MainNavbarComponent from "../components/MainNavbar";

export default function HomeRoute()
{
  return (
    <div>
      <MainNavbarComponent />

      <div className="content-holder">
        {/* <h2>A thing that everyone can use...</h2>
        <button className="donate-button">Donate</button> */}


        <div className="normal-align-div">
          <h2>About it</h2>
          
          <h3>How many people are working on it?</h3>
          <div className="white-text-div">
            {`Currently it's just me, But in the feature I can't do it on my own,
            Anyone can join the team if he/she is the right person. I'll let you know in the Twitter.`}
          </div>

          <h3>What is this idea?</h3>
          <div className="white-text-div">
            {`I can't give any information about the project right now, Or it will be created by a 
            random company.`}
          </div>

          <h3>Why don't you just give/sell the idea to a random company?</h3>
          <div className="white-text-div">
            {`I have many reasons to don't do that, Actully I love this idea and I know how to 
            get it done, But there is some more important reason that it provide me from doing such 
            a thing. If a random company create this thing, Only few investors of the company will earn
            profit; I don't like it! Anyone in the world must be free to earn money from this project, 
            I think the second reason is enoghf for us to build it on our own.`}
          </div>


          <h3>What is the starting budget and whats next?</h3>
          <div className="white-text-div">
            {`$30k for getting started. After collecting this budget, Building will be started and 
            from now on anyone can safely invest on the project.`}
          </div>

          <h3>How can I invest? How it work?</h3>
          <div className="white-text-div">
            {`There will be a portal and you can easily invest on the project. Your invests will be in 
            your crypto wallet and it will be a Ethereum ERC20 token. (There 
            is a change to be different in feature)`}
          </div>

          <h3>Is this another useless cryptocurrency?</h3>
          <div className="white-text-div">
            {`THIS IS NOT A CRYPTOCURRENCY!, We are using cryptocurrency for a safe and easy 
            way to let anyone invest.`}
          </div>

          <h3>Why your starting budget is too low?</h3>
          <div className="white-text-div">
            {`There will be so much work to build this project, I can invest around $30k. but
            I need $30k more to have around $60k budget or project will die and my invested money too.`}
          </div>

          <h3>If I donate on this project, It mean I'm investing?</h3>
          <div className="white-text-div">
            {`No, Because portal is not created yet! 
            (If your donate record saved on donate collector websites like Patreon, 
              It will convert to your crypto wallet, 
              But you need to contact us after portal created) 
              But if you want to invest on the project 
              you can contact me on twitter or byhumanforhuman@hotmail.com.`}
          </div>


          <h3>What is portal?</h3>
          <div className="white-text-div">
            {`...`}
          </div>



          <h3>How long it take to project create after $30k collected?</h3>
          <div className="white-text-div">
            {`...`}
          </div>



        </div>
      
      </div>
      
      
    </div>
  );
};


